import Utils from '../../src/utils/utils';

// TRANSLATE MESSAGE
$(".translate-message").on("click", function () {
    translateMessage($(this), false);
});

function translateMessage(element, conversation) {
    var textOriginal = element.parent().next().html();
    var textTranslate = null;
    var locale = element.attr('data-locale');
    var id = element.attr('data-id');
    var logType = 'Message';

    // Already translate ?
    if (element.parent().next().find('.text-translate').length) { // Yes
        // Text
        element.parent().next().find('.text-original').hide();
        element.parent().next().find('.text-translate').show();
        // Button
        element.hide();
        element.parent().find('.text-original-message').show();
    } else {
        // Spinner
        element.parent().next().html('<div id="floatingCirclesG" class="bar translate-spinner"><div class="f_circleG" id="frotateG_01"></div><div class="f_circleG" id="frotateG_02"></div><div class="f_circleG" id="frotateG_03"></div><div class="f_circleG" id="frotateG_04"></div><div class="f_circleG" id="frotateG_05"></div><div class="f_circleG" id="frotateG_06"></div><div class="f_circleG" id="frotateG_07"></div><div class="f_circleG" id="frotateG_08"></div></div>');

        if (conversation) {
            element.hide();
            logType = 'Conversation'
        }

        // Translate
        $.post( "/"+locale+"/translator", { textOriginal: textOriginal, toLanguage: locale, logType: logType, id: id })
            .done(function( result ) {
                textTranslate = result.translate;

                if (textTranslate === null) { // Error
                    element.parent().next().find('.translate-spinner').remove(); // Remove spinner
                    element.parent().next().html('<p class="text-original">'+textOriginal+'</p>'); // Text original
                    element.hide(); // Hide button
                    element.parent().find('.error-translate-message').show(); // Show error
                    element.parent().find('.error-translate-message').show(); // Show error
                } else {
                    textTranslate = '<p class="text-translate">'+textTranslate+'</p>';
                    element.parent().next().find('.translate-spinner').remove(); // Remove spinner
                    element.parent().next().html(textTranslate+'<p class="text-original" style="display: none;">'+textOriginal+'</p>'); // Text translate
                    element.hide(); // Hide button
                    element.parent().find('.text-original-message').show(); // Show btn original message
                }
            })

            .fail(function () {
                element.parent().next().find('.translate-spinner').remove(); // Remove spinner
                element.parent().next().html('<p class="text-original">'+textOriginal+'</p>'); // Text original
                element.hide(); // Hide button
                element.parent().find('.error-translate-message').show(); // Show error
            })
        ;
    }
}

// ORIGINAL TEXT MESSAGE
$(".text-original-message").on("click", function () {
    // Text
    $(this).parent().next().find('.text-translate').hide();
    $(this).parent().next().find('.text-original').show();
    // Button
    $(this).hide();
    $(this).parent().find('.translate-message').show();
});


// ----------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------


// TRANSLATE CONVERSATION
$(".translate-conversation").on("click", function () {
    var element = $(this);

    $(".translate-message").each(function() {
        var child = $(this);
        // Already error ?
        if( !child.parent().find('.error-translate-message').is(':visible') ) {
            translateMessage(child, true);
        }
    });

    element.hide();
    $(this).parent().find('.text-original-conversation').show();
});

// ORIGINAL CONVERSATION
$(".text-original-conversation").click(function () {
    var element = $(this);

    $(".text-original-message").each(function() {
        var child = $(this);
        // Already error ?
        if( !child.parent().find('.error-translate-message').is(':visible') ) {
            // Text
            child.parent().next().find('.text-translate').hide();
            child.parent().next().find('.text-original').show();
            // Button
            child.hide();
            child.parent().find('.translate-message').show();
        }
    });

    element.hide();
    element.parent().find('.translate-conversation').show();
});

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------

// TRANSLATE HOME
$(".translate-home").on("click", function () { // Btn translate
    var element = $(this);
    var errorMessage = element.parent().attr('data-error');
    var loadingMessage = element.parent().attr('data-loading');
    var localeToTranslate = element.attr('data-locale'); // Local user
    var localeCurrent = $('.described_language_home .change-description[data-select]').attr('data-locale'); // Local text
    var variables = ["good_feature", "good_place", "other"];
    var id =  element.attr('data-id');

    // Already translate ?
    if ($('#good_feature-'+localeCurrent+'-translate').length) { // Yes
        // Translate
        $('#description_type').html($('#description_type-'+localeToTranslate).html());
        $.each(variables, function( index, value ) {
            var textTranslate = $('#'+value+'-'+localeCurrent+'-translate').html();
            // p
            $('#'+value).html(textTranslate);
        });

        // Button
        element.hide();
        $(this).parent().find('.translate-home').show();
    }
    else {
        // Translate
        $('#description_type').html($('#description_type-'+localeToTranslate).html());
        $.each(variables, function( index, value ) {
            var textOriginal = $('#'+value+'-'+localeCurrent).html();

            if (textOriginal != "") {
                // Loading Message
                $('#'+value).text(loadingMessage);

                // Translate
                $.post( "/"+localeToTranslate+"/translator", { textOriginal: textOriginal, toLanguage: localeToTranslate, logType: 'Home', id: id })
                    .done(function( result ) {
                        var textTranslate = result.translate;

                        if (textTranslate === null) { // Error
                            // p
                            $('#'+value).html(textOriginal+' <span class="red-color">['+errorMessage+']</span>');
                            // input translate
                            $('#home-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textOriginal+' <span class="red-color">['+errorMessage+']</span></p>');
                        } else {
                            // p
                            $('#'+value).html(textTranslate);
                            // input translate
                            $('#home-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textTranslate+'</p>');
                        }
                    })

                    .fail(function () {
                        // p
                        $('#'+value).text(textOriginal+' <span class="red-color">['+errorMessage+']</span>');
                        // input translate
                        $('#home-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textOriginal+' <span class="red-color">['+errorMessage+']</span></p>');
                    })
                ;
            } else {
                // p
                $('#'+value).html(textOriginal);
                // input translate
                $('#home-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textOriginal+'</p>');
            }
        });
    }

    // Button
    element.hide();
    $(this).parent().find('.text-original-home').show();
});

$(".text-original-home").on("click", function () { // Btn text original
    var element = $(this);
    var localeCurrent = $('.described_language_home .change-description[data-select]').attr('data-locale'); // Local text

    // Variables
    var variables = ["good_feature", "good_place", "other"];

    // Translate
    $('#description_type').html($('#description_type-'+localeCurrent).html());
    $.each(variables, function( index, value ) {
        var textOriginal = $('#'+value+'-'+localeCurrent).html();
        // p
        $('#'+value).html(textOriginal);
    });

    // Button
    element.hide();
    $(this).parent().find('.translate-home').show();
});

// ----------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------

// TRANSLATE USER
$(".translate-user").on("click", function () { // Btn translate
    var element = $(this);
    var errorMessage = element.parent().attr('data-error');
    var loadingMessage = element.parent().attr('data-loading');
    var localeToTranslate = element.attr('data-locale'); // Local user
    var localeCurrent = $('.described_language .change-description[data-select]').attr('data-locale'); // Local text
    var variables = ["description"];
    var id = element.attr('data-id');

    // Already translate ?
    if ($('#description-'+localeCurrent+'-translate').length) { // Yes
        // Translate
        $('#tidiness').html($('#tidiness-'+localeToTranslate).html());
        $('#planning').html($('#planning-'+localeToTranslate).html());
        $.each(variables, function( index, value ) {
            var textTranslate = $('#'+value+'-'+localeCurrent+'-translate').html();
            $('#'+value).html(textTranslate);
        });

        // Button
        element.hide();
        $(this).parent().find('.translate-user').show();
    }
    else {
        // Translate
        $('#tidiness').html($('#tidiness-'+localeToTranslate).html());
        $('#planning').html($('#planning-'+localeToTranslate).html());
        $.each(variables, function( index, value ) {
            var textOriginal = $('#'+value+'-'+localeCurrent).html();

            if (textOriginal != "") {
                // Loading Message
                $('#'+value).text(loadingMessage);

                // Translate
                $.post( "/"+localeToTranslate+"/translator", { textOriginal: textOriginal, toLanguage: localeToTranslate, logType: 'Profile', id: id })
                    .done(function( result ) {
                        var textTranslate = result.translate;

                        if (textTranslate === null) { // Error
                            $('#'+value).html(textOriginal+' <span class="red-color">['+errorMessage+']</span>');
                            // p translate
                            $('#user-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textOriginal+' <span class="red-color">['+errorMessage+']</span></p>');
                        } else {
                            $('#'+value).html(textTranslate);
                            // p translate
                            $('#user-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textTranslate+'</p>');
                        }
                    })

                    .fail(function () {
                        $('#'+value).html(textOriginal+' <span class="red-color">['+errorMessage+']</span>');
                        // p translate
                        $('#user-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textOriginal+' <span class="red-color">['+errorMessage+']</span></p>');
                    })
                ;
            } else {
                $('#'+value).html(textOriginal);
                // p translate
                $('#user-description').append('<p style="display: none;" id="'+value+'-'+localeCurrent+'-translate">'+textOriginal+'</p>');
            }
        });
    }

    // Button
    element.hide();
    $(this).parent().find('.text-original-user').show();
});

$(".text-original-user").on("click", function () { // Btn text original
    var element = $(this);
    var localeCurrent = $('.described_language .change-description[data-select]').attr('data-locale'); // Local text

    // Variables
    var variables = ["description"];

    // Translate
    $('#tidiness').html($('#tidiness-'+localeCurrent).html());
    $('#planning').html($('#planning-'+localeCurrent).html());
    $.each(variables, function( index, value ) {
        var textOriginal = $('#'+value+'-'+localeCurrent).html();
        $('#'+value).html(textOriginal);
    });

    // Button
    element.hide();
    $(this).parent().find('.translate-user').show();
});
