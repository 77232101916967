/**
 * Load the Facebook apk and load the facebook action on the sponsor and invite buttons
 */
window.facebook = function () {
    facebookApk();

    $(".social-friend .btn").unbind("click").click(function (e){
        e.preventDefault();
        url = $(this).attr("data-sponsor");
        FB.ui({
            method: 'send',
            link: url,
            to: $(this).attr("data-id")
        });
    });

    $(".facebook-friends .btn-sponsor, .fb-process .btn-sponsor").unbind("click").click(function (e){
        e.preventDefault();
        url = $(this).attr("data-sponsor");
        FB.ui({
            method: 'send',
            link: url
        });
    });

    slideSocialFriends();

    $(".more-facebook-friends").unbind("click").click(function(e){
        var i = 0;
        while(i<8 && i< $(".facebook-friends .col-md-3.hide").length){
            $($(".facebook-friends .col-md-3.hide")[0]).toggleClass("hide");
            i++;
        }
        return false;
    });
}

window.facebookConnect = function () {
    facebookApk();
    $(".facebook-login").click(function(e){
        e.preventDefault();
        var url = $(this).attr("href");
        var scop = $(this).attr("data-scope");
        if (navigator.userAgent.match('CriOS')) {
            window.open(url, '', null);
        } else {
            FB.login(function(response) {
                var redirectTo = $.urlParam('redirectTo');
                if (response.authResponse) {
                    if (redirectTo) {
                        // Because of Facebook's API, we can't give the redirect parameter in the URL, so we use cookies
                        createCookie('redirectTo', redirectTo, 1);
                    } else {
                        // Make sure we have no redirection cookie
                        createCookie('redirectTo', '', -1);
                    }
                    window.location = url;
                }
            }, {scope: scop});
        }
    });
}

/**
 * Slider for the facebook friends displayed on the top bar when you logged with facebook
 */
window.slideSocialFriends = function () {
    $("#prev-friends").unbind("click").click(function(e){
        if($(this).parent().children().index($(".facebook-friends .col-xs-6").not('.hide'))==1){
            $(".facebook-friends .col-xs-6").addClass("hide");
            $(".facebook-friends .col-xs-6:nth-child("+($(".facebook-friends .col-xs-6").length+1)+")").toggleClass("hide");
            $(".facebook-friends .col-xs-6:nth-child("+($(".facebook-friends .col-xs-6").length)+")").toggleClass("hide");
        } else {
            var index = $(this).parent().children().index($(".facebook-friends .col-xs-6").not('.hide'));
            $(".facebook-friends .col-xs-6").addClass("hide");
            $(".facebook-friends .col-xs-6:nth-child("+(index)+")").toggleClass("hide");
            $(".facebook-friends .col-xs-6:nth-child("+(index-1)+")").toggleClass("hide");
        }
    });

    $("#next-friends").unbind("click").click(function(e){
        if($(this).parent().children().index($(".facebook-friends .col-xs-6").not('.hide')) == $(".facebook-friends .col-xs-6").length){
            $(".facebook-friends .col-xs-6").addClass("hide");
            $(".facebook-friends .col-xs-6:nth-child(2)").toggleClass("hide");
            $(".facebook-friends .col-xs-6:nth-child(3)").toggleClass("hide");
        } else {
            var index = $(this).parent().children().index($(".facebook-friends .col-xs-6").not('.hide'));
            $(".facebook-friends .col-xs-6").addClass("hide");
            $(".facebook-friends .col-xs-6:nth-child("+(index+3)+")").toggleClass("hide");
            $(".facebook-friends .col-xs-6:nth-child("+(index+4)+")").toggleClass("hide");
        }
    });
}

window.facebookApk = function () {
    var clientId;
    clientId = $('meta[property="fb:app_id"]').attr('content');

    window.fbAsyncInit = function() {
        FB.init({
            appId      : clientId,
            xfbml      : true,
            version    : 'v3.2'
        });
    };
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        var locale = $('body').data('locale').replace('-', '_');
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/" + locale + "/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

facebookApk();
